<template>
  <div class="society-content mx-8 md:mx-28 flex flex-1 flex-col justify-around">
    <div class="society-text flex flex-col items-start mt-8">
      <p class="font-light text-xl md:text-xxl" v-html="texts[step].text"></p>
      <div v-if="step === texts.length - 1 && $route.name === 'explanation'" class="mt-10 mx-auto">
        <NCta
          class="signup-button"
          :borderRadius="'0px'"
          :backgroundColor="{default: colorStyle.tertiary, hover: colorStyle.quinary}"
          :textColor="{default: 'white', hover: colorStyle.tertiary}"
          :borderColor="{default: colorStyle.quinary, hover: colorStyle.quinary}"
          @click.native="goToRegister()"
          ref="signup_button"
        >SIGN UP</NCta>
      </div>
    </div>
    <!-- NAVIGATION -->
    <div class="nav flex flex-row space-around justify-end mb-8 sm:mb-0">
      <div class="arrow up-arrow cursor-pointer" :class="{'hidden-arrow': step === 0}" @click="previousStep()">
        <img src="../assets/icons/up-arrow.svg" alt="">
      </div>
      <div class="arrow down-arrow cursor-pointer" :class="{'hidden-arrow': step === texts.length - 1}" @click="nextStep()">
        <img src="../assets/icons/down-arrow.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import design from '@/validators/design'
import gsap from "gsap";
export default {
  name: 'SocietyText',
  props: {
    delay: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      stepTimer: null,
      animating: false,
      step: 0,
      texts: [
        {
          id: 1,
          text: 'You are about to enter <span class="font-sangbleukingdom text-quinary">The LOUIS XIII Mysteries</span>, a secret and unsung place beyond time and space'
        },
        {
          id: 2,
          text: 'In this universe, your mission will be to find <span class="font-sangbleukingdom text-quinary">XIII</span> keys to unlock your chance to live a sensorial LOUIS XIII experience'
        },
        {
          id: 3,
          text: 'Find the hidden codes in each <span class="font-sangbleukingdom text-quinary">enigma</span> to unlock the keys. One code might already be in these messages…'
        }
      ],
      xDown: null,
      yDown: null
    }
  },
  destroyed () {
    clearTimeout(this.stepTimer)
    window.removeEventListener('touchstart', this.handleTouchStart)
    window.removeEventListener('touchmove', this.handleTouchMove)
  },
  mounted() {
    let tlContent = gsap.timeline()
    tlContent.to('.society-content', {delay: 0 + this.delay, duration: 1.2, y: 0, opacity: 1})
    // Start timer
    this.startTimer(10000)

    window.document.addEventListener('touchstart', this.handleTouchStart, false);        
    window.document.addEventListener('touchmove', this.handleTouchMove, false);
  },
  methods: {
    startTimer (ms) {
      this.stepTimer = setTimeout(() => {
        this.nextStep()
      }, ms)
    },
    previousStep() {
      if (this.step > 0 && !this.animating) {
        this.animating = true
        let tl = gsap.timeline()
        tl.to('.society-text', {duration: 1.2, y: -50, opacity: 0, onComplete: () => {
            this.step -= 1
            this.animating = false
          }}) 
          .to('.society-text', {duration: 0, y: 50, opacity: 0})
          .to('.society-text', {delay: 0.5,duration: 1.2, y: 0, opacity: 1})
        clearTimeout(this.stepTimer)
      }
    },
    nextStep() {
      clearTimeout(this.stepTimer)
      if (this.step < 2 && !this.animating) {
        this.animating = true
        let tl = gsap.timeline()
        tl.to('.society-text', {duration: 1.2, y: 50, opacity: 0, onComplete: () => {
            this.step += 1
            this.animating = false
          }})  
          .to('.society-text', {duration: 0, y: -50, opacity: 0})
          .to('.society-text', {duration: 1.2, y: 0, opacity: 1})
        this.startTimer(7000)
      }
    },
    goToRegister() {
      this.$router
        .push({ name: 'register', query: this.$route.query })
        .catch(console.log);
    },
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches // browser API or jQuery
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];                                      
      this.xDown = firstTouch.clientX;                                      
      this.yDown = firstTouch.clientY;                                      
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      const xUp = evt.touches[0].clientX;                                    
      const yUp = evt.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
        if (xDiff > 0) {
          /* left swipe */ 
          console.log('swipe left')
        } else {
          /* right swipe */
          console.log('swipe right')
        }                       
      } else {
        if (yDiff > 0) {
          /* up swipe */ 
          console.log('swipe up')
          this.nextStep()
        } else { 
          /* down swipe */
          console.log('swipe down')
          this.previousStep()
        }                                                                 
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;                                             
    }
  }
}
</script>

<style lang="scss" scoped>
  .society-content {
    opacity: 0;
    transform: translateY(30px);
    @media screen and (min-width: 1200px) {
      margin-top: 180px;
    }
    .society-text {
      height: 200px;
      max-width: 1105px;
      margin-left: auto;
      margin-right: auto;
      .signup-button {
        width: 198px;
      }
    }
  }
  .up-arrow, .down-arrow {
    transition: all 1.2s ease;
    transform: translateY(0px);
  }
  .hidden-arrow {
    opacity: 0;
    &.up-arrow {
      transform: translateY(-30px);
    }
    &.down-arrow {
      transform: translateY(30px);
    }
  }
</style>