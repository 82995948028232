<template>
  <div class="society-container flex flex-col justify-around">
    <img class="logo mx-auto py-8" src="../assets/logos/louisxiii-white.svg" alt="">
    <SocietyText :delay="3" />
  </div>
</template>

<script>
import SocietyText from '@/components/SocietyText.vue'
import gsap from "gsap"
export default {
  name: 'Explanation',
  components: {  
    SocietyText
  },
  mounted() {
    this.$mmTro.sendRtg()
    this.$gtmTracking.pageView()
    let tlContainer = gsap.timeline()
    tlContainer.to('.society-container', {delay: 1, duration: 1.2, opacity: 1})
    let tlLogo = gsap.timeline()
    tlLogo.to('.logo', {delay: 1, duration: 1.2, y: 0, opacity: 1})
  }
}
</script>

<style lang="scss" scoped>
  .society-container {
    background: url('../assets/bg/intro-min.jpg');
    background-size: cover;
    height: 100vh;
    opacity: 0;
    transition: all 1.2s ease;
    @media screen and (min-width: 768px) {
      background: url('../assets/bg/intro.jpg');
      background-size: cover;
    }
    .logo {
      transition: all 1.2s ease;
      opacity: 0;
      transform: translateY(-30px);
    }
  }
</style>